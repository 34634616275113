<template>
<!-- 参数管理 -->
  <div class="box100">
    <div class="whiteBg">
      <div class="flexCB">
        <div class="flexAC" style="margin: 16px 0px;">
          <el-input v-model="name"  placeholder="请输入名称" class="inputSearch"></el-input>
          <el-button  class="btnSearch"  @click="handleSearch">搜索</el-button>
          <el-button  class="btnReset"  @click="handleReset">重置</el-button>
        </div>
        <div class="el-icon-plus settingAdd"  @click="handleAdd"><span> 新增</span></div>
      </div>
      <div class="table_cont">
        <el-table
          :data="parameList"
          :header-cell-style="{background:'#F6F9FF'}">
          <el-table-column label="序号" width="80">
            <template #default="scope"><span>{{(page - 1) * size + scope.$index + 1}}</span></template>
          </el-table-column>
          <el-table-column
            label="名称"
            prop="name">
          </el-table-column>
          <el-table-column
            label="单位"
            prop="unit">
          </el-table-column>
          <el-table-column
          label="操作">
            <template slot-scope="scope">
              <el-button type="text" size="small"  style="color:#FF4C4C;" @click="handleDelete(scope.row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>

        <el-pagination
          @current-change="pageChange"
          :current-page="page"
          :page-sizes="[10]"
          :page-size="size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          class="settingPage">
        </el-pagination>
      </div>
    </div>
    <!-- 新增 -->
    <el-dialog title="新增" :visible.sync="fromShow" center width="460px" class="gyDialog" :close-on-click-modal="false" :before-close="FromCancel">
      <el-form :model="parfrom" :rules="formRules" ref="parFrom">
        <el-form-item label="名称" prop="name">
          <el-input v-model="parfrom.name"  placeholder="请输入名称"></el-input>
        </el-form-item>
        <el-form-item label="单位" prop="unit">
          <el-input v-model="parfrom.unit"  placeholder="请输入单位名称"></el-input>
        </el-form-item>
      </el-form>
      <div class="flexCE">
        <div class="settingCancel" @click="FromCancel">取消</div>
        <div class="settingConfirm" @click="FromConfirm">确定</div>
      </div>
    </el-dialog>
  </div>
</template>
  
<script>
import { parameterGet, parameterDel, parameterAdd } from '@/api/port.js'
export default {
  data () {
    return {
      name: '',
      parameList: [],
      page: 1,
      size: 10,
      total: 0,
      fromShow: false,
      parfrom: { name: '', unit: ''},
      formRules: {
        name: [
          { required: true, trigger: 'blur', message: '名称不能为空' },
          { min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur' }
        ],
        unit: [
          { required: true, trigger: 'blur', message: '单位名称不能为空' },
          { min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur' }
        ]
      }
    }
  },
  created () {
    this.init()
  },
  methods: {
    init () {
      const params = {
        name: this.name,
        page_index: this.page,
        page_size: this.size
      }
      parameterGet(params).then(res => {
        if (res.status === 1) {
          this.parameList = res.data.data
          this.total = res.data.total
        } else {
          this.parameList = []
          this.total = 0
        }
      })
    },
    // 新增
    handleAdd () {
      this.fromShow = true
    },
    FromCancel () {
      this.$refs.parFrom.resetFields()
      this.fromShow = false
    },
    FromConfirm () {
      this.$refs.parFrom.validate((valid) => {
        if (!valid) return
        const params = {
          name: this.parfrom.name,
          unit: this.parfrom.unit,
        }
        parameterAdd(params).then(res => {
          if (res.status === 1) {
            this.$message.success(res.message)
            this.FromCancel()
            this.init()
          } else {
            this.$message.error(res.message)
          }
        })
      })
    },
    // 删除
    handleDelete (row) {
      this.$confirm('此操作将永久删除该信息, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true
      }).then(() => {
        parameterDel({ id: row.id }).then(res => {
          if (res.status === 1) {
            this.$message.success(res.message)
            this.init()
          } else {
            this.$message.error(res.message)
          }
        })
      }).catch(() => {})
    },
    pageChange (page) {
      this.page = page
      this.init()
    },
    handleSearch () {
      this.init()
    },
    handleReset () {
      this.name = ''
      this.page = 1
      this.init()
    }
  }
}
</script>

<style scoped>

</style>
